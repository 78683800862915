import React, { lazy, Suspense } from 'react'
import Loader from './components/Loader'
import { HashRouter as Router, Route, Switch, Routes } from 'react-router-dom'; // Use HashRouter instead of BrowserRouter
const Home = lazy(() => import('./components/Navbar'))
const Privacy = lazy(() => import('./screen/Privacy'))
const Terms = lazy(() => import('./screen/TermsCondition'))

const App = () => {
  return (
    <>
      <Router>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route exact path="/privacy" element={<Privacy />} />
            <Route exact path="/terms" element={<Terms />} />
            <Route exact path="/" element={<Home />} />
          </Routes>
        </Suspense>
      </Router>
    </>
  )
}

export default App
