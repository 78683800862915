import React from 'react';
import logo from '../assets/logo.png';

const Loader = () => {
  return (
    <>
      <div className="main_loader">
            <img src={logo} alt="logo" className='loader_image_width'/>
      </div>
    </>
  )
}

export default Loader
